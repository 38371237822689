<template>
  <div class="score-preview">
    <img :src="`/assets/incipits/${piece.sku}_self_Score_prelude.svg`" />
    <hr />
    <img :src="`/assets/incipits/${piece.sku}_self_Score_fugue.svg`" />
  </div>
</template>

<script>
export default {
  name: 'ScorePreview',
  props: {
    piece: Object,
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';
.score-preview {
  text-align: center;
  border: 1px solid #bbbbbb;
  background-color: $background;
  overflow: hidden;
  padding: 24px;

  img {
    margin: 8px;
  }
  hr {
    background-color: $purple;
    height: 7px;
  }
}
@include mobile {
  .score-preview {
    min-width: 350px;
  }
}
</style>
